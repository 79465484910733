import mhghLogo from "../../assets/logos/mhghLogo.png";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { scroller } from "react-scroll"; // Import react-scroll's scroller for smooth scrolling
import { useState } from "react";
import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from "@headlessui/react";
import {
  ArrowPathIcon,
  Bars3Icon,
  GlobeAsiaAustraliaIcon,
  DevicePhoneMobileIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  BriefcaseIcon,
  UserGroupIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  PhoneIcon,
  PlayCircleIcon,
} from "@heroicons/react/20/solid";

const researchProjects = [
  {
    name: "Overview",
    description: "Mobile & Global Health Software",
    href: "/research#overview",
    icon: GlobeAsiaAustraliaIcon,
  },
  {
    name: "Connected Dx",
    description: "Improving Point-of-Care Testing",
    href: "/research#connected-dx",
    icon: DevicePhoneMobileIcon,
  },
  {
    name: "Epi Infrastructure",
    description: "Epidemiology Surveillance Infrastructure",
    href: "/research#epi-infra",
    icon: MagnifyingGlassIcon,
  },
  // {
  //   name: "Predictive Analytics",
  //   description: "Predictive Analytics for Infectious Disease",
  //   href: "/research#pred-model",
  //   icon: FingerPrintIcon,
  // },
  {
    name: "Program Management",
    description: "Program Management for Global Health",
    href: "/research#prog-mgmt",
    icon: BriefcaseIcon,
  },
  {
    name: "mHealth Usability",
    description: "mHealth Usability",
    href: "/research#mhealth-usability",
    icon: UserGroupIcon,
  },
];
const callsToAction = [
  { name: "Overview", href: "#", icon: PlayCircleIcon },
  { name: "More projects", href: "#", icon: PhoneIcon },
];
const aboutSections = [
  { name: "Mission", href: "/home#mission" },
  { name: "Who We Are", href: "/home#team" },
  { name: "Contact", href: "/home#contact" },
];

export default function TopNav() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const location = useLocation();

  // This useEffect ensures that the scroll happens when we navigate to a hash in the current page
  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      scroller.scrollTo(hash.substring(1), {
        smooth: true,
        duration: 500,
        offset: -70, // Adjust this value depending on your header's height
      });
    } else {
      scroller.scrollTo("top", {
        smooth: true,
        duration: 500,
        offset: 0,
      });
    }
  }, [location]);

  return (
    <header className="bg-white shadow-md sticky top-0 z-20">
      <nav
        aria-label="Global"
        className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
      >
        <div className="flex lg:flex-1 items-center">
          <Link to={"/home"} className="-m-1.5 p-1.5">
            <span className="sr-only">MHGH @ Vandy</span>
            <img alt="" src={mhghLogo} className="h-12 w-auto" />
          </Link>
          <span className="ml-2 text-mhghBlack-600 font-bold">
            <span className="hidden md:inline">
              Mobile Health for Global Health Lab @ Vanderbilt
            </span>
            <span className="inline md:hidden">MHGH Lab @ Vanderbilt</span>
          </span>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-mhghBlack-600"
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
        <PopoverGroup className="hidden lg:flex lg:gap-x-12">
          <Popover className="relative">
            <PopoverButton className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-mhghBlack-600 hover:text-mhghBlack-800">
              About Us
              <ChevronDownIcon
                aria-hidden="true"
                className="h-5 w-5 flex-none text-mhghBlack-600"
              />
            </PopoverButton>

            <PopoverPanel
              transition
              className="absolute -left-8 top-full z-10 mt-3 w-56 rounded-xl bg-white p-2 shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
            >
              {aboutSections.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  smooth={true}
                  duration={500}
                  className="block rounded-lg px-3 py-2 text-sm font-semibold leading-6 text-mhghBlack-600 hover:bg-gray-50 cursor-pointer"
                >
                  {item.name}
                </Link>
              ))}
            </PopoverPanel>
          </Popover>
          <Popover className="relative">
            <PopoverButton className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-mhghBlack-600">
              Research
              <ChevronDownIcon
                aria-hidden="true"
                className="h-5 w-5 flex-none text-mhghBlack-600"
              />
            </PopoverButton>

            <PopoverPanel
              transition
              className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <div className="p-4">
                {researchProjects.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    smooth={true}
                    duration={500}
                    className="group relative flex gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                  >
                    <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                      <item.icon
                        aria-hidden="true"
                        className="h-6 w-6 text-mhghBlack-600 group-hover:text-mhghBlack-800"
                      />
                    </div>
                    <div className="flex-auto">
                      <a
                        href={item.href}
                        className="block font-semibold text-mhghBlack-600"
                      >
                        {item.name}
                        <span className="absolute inset-0" />
                      </a>
                      <p className="mt-1 text-mhghBlack-600">
                        {item.description}
                      </p>
                    </div>
                  </Link>
                ))}
              </div>
              {/* <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                {callsToAction.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-mhghBlack-600 hover:bg-gray-100"
                  >
                    <item.icon
                      aria-hidden="true"
                      className="h-5 w-5 flex-none text-mhghBlack-600"
                    />
                    {item.name}
                  </a>
                ))}
              </div> */}
            </PopoverPanel>
          </Popover>
          <Link
            to={"/publications"}
            className="text-sm font-semibold leading-6 text-mhghBlack-600"
          >
            Publications
          </Link>
          <Link
            to={"/software"}
            className="text-sm font-semibold leading-6 text-mhghBlack-600"
          >
            Software
          </Link>
        </PopoverGroup>
      </nav>
      <Dialog
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
        className="lg:hidden"
      >
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to={"/home"} className="-m-1.5 p-1.5">
              <span className="sr-only">MHGH @ Vandy</span>
              <img alt="" src={mhghLogo} className="h-12 w-auto" />
            </Link>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-mhghBlack-600"
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Disclosure as="div" className="-mx-3">
                  <DisclosureButton className="group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-mhghBlack-600 hover:bg-gray-50">
                    About Us
                    <ChevronDownIcon
                      aria-hidden="true"
                      className="h-5 w-5 flex-none group-data-[open]:rotate-180"
                    />
                  </DisclosureButton>
                  <DisclosurePanel className="mt-2 space-y-2">
                    {aboutSections.map((item) => (
                      <DisclosureButton
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-mhghBlack-600 hover:bg-gray-50"
                      >
                        {item.name}
                      </DisclosureButton>
                    ))}
                  </DisclosurePanel>
                </Disclosure>
                <Disclosure as="div" className="-mx-3">
                  <DisclosureButton className="group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-mhghBlack-600 hover:bg-gray-50">
                    Research
                    <ChevronDownIcon
                      aria-hidden="true"
                      className="h-5 w-5 flex-none group-data-[open]:rotate-180"
                    />
                  </DisclosureButton>
                  <DisclosurePanel className="mt-2 space-y-2">
                    {[...researchProjects].map((item) => (
                      <DisclosureButton
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-mhghBlack-600 hover:bg-gray-50"
                      >
                        {item.name}
                      </DisclosureButton>
                    ))}
                  </DisclosurePanel>
                </Disclosure>
                <Link
                  to={"/publications"}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-mhghBlack-600 hover:bg-gray-50"
                >
                  Publications
                </Link>
                <Link
                  to={"/software"}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-mhghBlack-600 hover:bg-gray-50"
                >
                  Software
                </Link>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
}
