import { useState } from "react";
// Bio photo imports
import tomScherr from "../../assets/images/team/tomScherr.png";
import austinHardcastle from "../../assets/images/team/austinHardcastle.png";
import dheemanthMajji from "../../assets/images/team/deemanthMajji.jpeg";
import mattBoisse from "../../assets/images/team/mattBoisse.jpeg";
import srinidhiArumugam from "../../assets/images/team/srinidhiArumugam.jpg";
import carsonMoore from "../../assets/images/team/carsonMoore.jpeg";
import chanceIneza from "../../assets/images/team/chanceIneza.jpg";
import chloeNixon from "../../assets/images/team/chloeNixon.jpeg";
import jerryChen from "../../assets/images/team/jerryChen.jpeg";
import kellyChen from "../../assets/images/team/kellyChen.jpeg";
import qiushiYan from "../../assets/images/team/qiushiYan.jpg";
import vanDang from "../../assets/images/team/vanDang.jpeg";
import niaLegba from "../../assets/images/team/niaLegba.jpeg";
import {
  UserCircleIcon,
  EllipsisHorizontalCircleIcon,
} from "@heroicons/react/24/outline";
// Component imports
import TeamSelector from "./TeamSelector";
import TeamModal from "./TeamModal";

const people = [
  {
    firstName: "Tom",
    lastName: "Scherr",
    role: "pi",
    title: "Research Associate Professor",
    status: "active",
    imageComponent: tomScherr,
    xUrl: "#",
    linkedinUrl: "https://www.linkedin.com/in/tscherr/",
    email: "thomas.f.scherr@vanderbilt.edu",
    details: {
      affiliation: "Vanderbilt University",
      affiliationTitle: "Principal Investigator",
      education: [
        "Postdoctoral Scholar, Biomedical Engineering, Vanderbilt University",
        "Ph.D., Chemical Engineering, Louisiana State University",
        "B.S., Chemical Engineering, University of Delaware",
      ],
      researchInterests: "Mobile Health, Diagnostics, Global Health, Logistics Management, Data Science",
      bio: [
        "Dr. Scherr is the Principal Investigator of the Mobile Health for Global Health (MHGH) Lab at Vanderbilt University. Here, he leads a team focused on applications of software development in global health settings. With formal training in Chemical Engineering, interdisciplinary research has been a hallmark of his career – he has extensive experience in the development and evaluation of point-of-care diagnostic tests, mobile and web application development, and data science and machine learning.",
      ],
    },
  },
  {
    firstName: "Austin",
    lastName: "Hardcastle",
    role: "staff",
    status: "alum",
    title: "Product Manager",
    imageComponent: austinHardcastle,
    xUrl: "#",
    linkedinUrl: "https://www.linkedin.com/in/austin-hardcastle/",
    details: {
      bio: [
        "An engineer by training with a passion for user-centered design, Austin Hardcastle brings experience from nonprofit, academic, startup, and corporate environments to the team as Product Manager. Austin was responsible for keeping our big-picture and day-to-day trajectories aimed at building impactful products that people love to use.",
        "Austin is now a Digital Experience Solutions Analyst at AllianceBernstein.",
      ],
    },
  },
  {
    firstName: "Matt",
    lastName: "Boisse",
    role: "staff",
    title: "Application Developer",
    status: "active",
    imageComponent: mattBoisse,
    xUrl: "#",
    linkedinUrl: "https://www.linkedin.com/in/matthew-boisse-b3a296224/",
    details: {
      bio: [
        "Matthew Boisse is an Application Engineer in the Mobile Health for Global Health group at Vanderbilt University. Who is a self-starter able to think both creatively and analytically, with a mind for patterns, spatial awareness, data crunching and puzzle solving. Known for being both a leader and a team player, with a love of collaboration and idea generation.",
      ],
    },
  },
  {
    firstName: "Carson",
    lastName: "Moore",
    role: "postdoc",
    title: "Postdoctoral Researcher",
    status: "alum",
    imageComponent: carsonMoore,
    xUrl: "#",
    linkedinUrl: "https://www.linkedin.com/in/carson-moore-phd/",
    details: {
      bio: [
        "Dr. Carson Moore was a postdoctoral researcher in the Mobile Health for Global Health group at Vanderbilt University. Dr. Moore is an analytical chemist by training with a specialty in infectious disease diagnostics and epidemiology. Dr. Moore is a passionate advocate for public and global health, an experienced field researcher, and provides practical expertise in translating benchtop science to the field.",
        "Carson is now a Public Health Advisor with the Neglected Tropical Diseases Support Center at Task Force for Global Health.",
      ],
    },
  },
  {
    firstName: "Chance",
    lastName: "Ineza",
    role: "undergraduate",
    title: "Buchanan Fellow",
    status: "active",
    imageComponent: chanceIneza,
    xUrl: "#",
    linkedinUrl: "https://www.linkedin.com/in/chance-ineza/",
    details: {bio: ["Chance is a Computer Science undergraduate, and her research in the MHGH lab has focused on the visualization of mobile network performance metrics in a web dashboard. More recently, she has led both front end and back end development on PIPER -- an easy-to-use image-processing pipeline builder web application."]},
  },
  {
    firstName: "Fiifi",
    lastName: "Godwell",
    role: "undergraduate",
    title: "Intern",
    status: "alum",
    imageComponent: null,
    xUrl: "#",
    linkedinUrl: null,
    details: {bio: ["Fiifi was a summer intern in the MHGH Lab where he focused on automations to measure and improve code quality, as well as other CI/CD pipeline functionalities."]},
  },
  {
    firstName: "Kelly",
    lastName: "Chen",
    role: "graduate",
    title: "Data Science MS Student",
    status: "alum",
    imageComponent: kellyChen,
    xUrl: "#",
    linkedinUrl: "https://www.linkedin.com/in/mengqingchen/",
    details: {},
  },
  {
    firstName: "Qiuishi",
    lastName: "Yan",
    role: "graduate",
    title: "Data Science MS Student",
    status: "alum",
    imageComponent: qiushiYan,
    xUrl: "#",
    linkedinUrl: "https://www.linkedin.com/in/qiushiyan/",
    details: {},
  },
  {
    firstName: "Chloe",
    lastName: "Nixon",
    role: "undergraduate",
    title: "Undergraduate Researcher",
    status: "alum",
    imageComponent: chloeNixon,
    xUrl: "#",
    linkedinUrl: "https://www.linkedin.com/in/chloe-nixon/",
    details: {},
  },
  {
    firstName: "Dheemanth",
    lastName: "Majji",
    role: "undergraduate",
    title: "Undergrad Researcher",
    status: "active",
    imageComponent: dheemanthMajji,
    xUrl: "#",
    linkedinUrl: "https://www.linkedin.com/in/dheemanth-s-majji/",
    details: {
      bio: [
        "Majji is a sophomore at Vanderbilt University pursuing a double concentration in applied mathematics and computer science . In the MHGH lab, he works with a 5+ year long HIV RDT study to find patterns, create visual representations, and draw conclusions based off of user-generated paradata. Additionally, he is performing a quantitative retrospective analysis of the MHGH team's agile practices. His long-term career goals are to work as a software engineer, and eventually move into the product management space.",
      ],
    },
  },
  {
    firstName: "Srinidhi",
    lastName: "Arumugam",
    role: "undergraduate",
    title: "Undergrad Researcher",
    status: "alum",
    imageComponent: srinidhiArumugam,
    xUrl: "#",
    linkedinUrl: "https://www.linkedin.com/in/srinidhi-arumugam-88061524b/",
    details: {bio: ["Srinidhi is a sophomore at Vanderbilt, double majoring in Biological Sciences and Computer Science. In the MHGH lab, she worked on UI/UX design and laboratory analysis of rapid diagnostic tests. Her long term career interests are in biomedical and healthcare informatics as well as medicine.", "Srinidhi is now enrolled at the University of Maryland College Park."]},
  },
  {
    firstName: "Jerry",
    lastName: "Chen",
    role: "undergraduate",
    title: "Undergrad Researcher",
    status: "active",
    imageComponent: jerryChen,
    xUrl: "#",
    linkedinUrl: "https://www.linkedin.com/in/longjichen/",
    details: {},
  },
  {
    firstName: "Nia",
    lastName: "Legba",
    role: "undergraduate",
    title: "Buchanan Fellow",
    status: "active",
    imageComponent: niaLegba,
    xUrl: "#",
    linkedinUrl: "https://www.linkedin.com/in/nia-legba-62070a271/",
    details: {},
  },
  {
    firstName: "Van",
    lastName: "Dang",
    role: "undergraduate",
    title: "Undergraduate Researcher",
    status: "active",
    imageComponent: vanDang,
    xUrl: "#",
    linkedinUrl: "https://www.linkedin.com/in/vnthanhdng/",
    details: {},
  },
];

export default function Team() {
  const [roleFilter, setRoleFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [selectedPerson, setSelectedPerson] = useState(null);

  const filteredPeople = people.filter((person) => {
    return (
      (roleFilter ? person.role === roleFilter : true) &&
      (statusFilter ? person.status === statusFilter : true)
    );
  });

  const resetFilters = () => {
    setRoleFilter("");
    setStatusFilter("");
  };

  const handleCloseModal = () => {
    setSelectedPerson(null);
  };
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Our team
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            We’re a dynamic group of individuals who are passionate about what
            we do and dedicated to positively impacting global health.
          </p>
        </div>
        <div className="my-8">
          <TeamSelector
            onRoleFilterChange={setRoleFilter}
            onStatusFilterChange={setStatusFilter}
            onResetFilters={resetFilters}
            roleFilter={roleFilter}
            statusFilter={statusFilter}
          />
        </div>
        <ul
          role="list"
          className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3"
        >
          {filteredPeople.map((person) => (
            <li key={person.name}>
              {person.imageComponent ? (
                <img
                  alt=""
                  src={person.imageComponent}
                  className="aspect-[3/2] w-full rounded-2xl object-cover"
                />
              ) : (
                <div className="aspect-[3/2] w-full bg-mhghBlack-400 flex items-center justify-center rounded-2xl text-white">
                  <UserCircleIcon className="h-24 w-24" />
                </div>
              )}
              <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">
                {person.firstName} {person.lastName}
              </h3>
              <p className="text-base leading-7 text-gray-600">
                {person.title}
                <span className="inline-flex items-center align-middle ml-2">
                  <button
                    type="button"
                    className="text-mhghDarkBlue-600 hover:text-mhghDarkBlue-700"
                    onClick={() => setSelectedPerson(person)}
                  >
                    <EllipsisHorizontalCircleIcon className="h-6 w-6" />
                  </button>
                </span>
              </p>
            </li>
          ))}
        </ul>

        {/* Modal */}
        {selectedPerson && (
          <TeamModal person={selectedPerson} onClose={handleCloseModal} />
        )}
      </div>
    </div>
  );
}
