import { useRef, useState } from "react";
import { MapPinIcon } from "@heroicons/react/24/outline";
import ReCAPTCHA from "react-google-recaptcha";

export default function Contact() {
  const recaptchaRef = useRef(null);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCaptchaComplete, setIsCaptchaComplete] = useState(false);

  const handleCaptchaChange = (value) => {
    setIsCaptchaComplete(!!value); // Set to true if reCAPTCHA is completed
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      alert("Please complete the reCAPTCHA before submitting.");
      return;
    }

    setIsSubmitting(true); // Disable the submit button while submitting

    try {
      const webhost = 'mhgh-web';

      const response = await fetch(
        "https://7xww630a5i.execute-api.us-east-1.amazonaws.com/Production/sendWebContactEmail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            message,
            webhost,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send email");
      }

      const result = await response.json();
      // console.log("Email sent successfully:", result);

      alert("Your message has been sent successfully!");
      recaptchaRef.current.reset(); // Reset the reCAPTCHA
      setEmail(""); // Clear email
      setMessage(""); // Clear message
      setIsCaptchaComplete(false); // Reset captcha state
    } catch (error) {
      console.error("Error sending email:", error);
      alert("There was an issue submitting your message. Please try again.");
    } finally {
      setIsSubmitting(false); // Re-enable the submit button
    }
  };

  const isFormValid =
    email.trim() !== "" && message.trim() !== "" && isCaptchaComplete;

  return (
    <div className="bg-gray-100">
      <div className="mx-auto max-w-7xl px-6 py-16 sm:py-24 lg:px-8">
        <div className="relative bg-white shadow-xl">
          <h2 className="sr-only">Contact us</h2>

          <div className="grid grid-cols-1 lg:grid-cols-3">
            <div className="relative bg-white px-6 py-10 sm:px-10 xl:p-12">
              <h3 className="text-lg font-medium text-gray-900">
                Contact information
              </h3>
              <p className="mt-6 max-w-3xl text-base text-gray-500">
                If you're interested in working with us, or have questions about
                our work, please get in touch.
              </p>
              <dl className="mt-8 space-y-6">
                <dd className="flex text-base text-mhghDarkBlue-50">
                  <MapPinIcon
                    aria-hidden="true"
                    className="h-6 w-6 flex-shrink-0 text-mhghDarkBlue-200"
                  />
                  <div>
                    <span className="ml-3">Stevenson Center 7330</span>
                    <br />
                    <span className="ml-3">1234 Stevenson Center Lane</span>
                    <br />
                    <span className="ml-3">Nashville, TN 37240</span>
                    <br />
                  </div>
                </dd>
              </dl>
            </div>

            {/* Contact form */}
            <div className="px-6 py-10 sm:px-10 lg:col-span-2 xl:p-12">
              <h3 className="text-lg font-medium text-gray-900">
                Send us a message
              </h3>
              <form
                onSubmit={handleSubmit}
                className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-1"
              >
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Email
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      autoComplete="email"
                      className="block w-full rounded-md border-gray-300 px-4 py-3 text-gray-900 shadow-sm focus:border-mhghDarkBlue-500 focus:ring-mhghDarkBlue-500"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="message"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Message
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="message"
                      name="message"
                      rows={4}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      className="block w-full rounded-md border-gray-300 px-4 py-3 text-gray-900 shadow-sm focus:border-mhghDarkBlue-500 focus:ring-mhghDarkBlue-500"
                    />
                  </div>
                </div>

                {/* Google reCAPTCHA */}
                <div>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6Lc0ioAqAAAAANiWuEwf4UE3foviEbbj1HZuXvct"
                    onChange={handleCaptchaChange}
                  />
                </div>

                <div className="flex justify-end">
                  <button
                    type="submit"
                    disabled={!isFormValid || isSubmitting}
                    className={`rounded-md px-3.5 py-2.5 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
                      !isFormValid || isSubmitting
                        ? "bg-gray-400 text-gray-700 cursor-not-allowed"
                        : "bg-mhghDarkBlue-800 text-white hover:bg-mhghDarkBlue-700"
                    }`}
                  >
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}