import { Link } from "react-router-dom";
import { BeakerIcon } from "@heroicons/react/24/outline";

export default function Software() {
  return (
    <>
      <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          {/* Center the Beaker icon */}
          <div className="flex justify-center">
            <BeakerIcon className="h-24 w-24 text-mhghOrange-600" />
          </div>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Open Source Software Coming Soon
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Our team is hard at work in the lab working to openly release much of our software.
          </p>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Stay tuned for exciting developments!
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              to={"/home#contact"}
              smooth={true}
              duration={500}
              className="rounded-md bg-mhghDarkBlue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-mhghDarkBlue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-mhghDarkBlue-600"
            >
              Notify Me
            </Link>
            <Link
              to={"/research#overview"}
              smooth={true}
              duration={500}
              className="text-sm font-semibold text-gray-900"
            >
              Learn More About Our Research{" "}
              <span aria-hidden="true">&rarr;</span>
            </Link>
          </div>
        </div>
      </main>
    </>
  );
}
