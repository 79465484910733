const TextPlusStatsPlusTestimonial = ({
  title,
  subtitle,
  text,
  stats,
  testimonial,
  imgUrl,
  testimonialImgUrl,
  testimonialBy,
  linkText,
  linkUrl,
  navButton,
}) => {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-4">
            <div className="relative overflow-hidden rounded-3xl bg-gray-900 px-6 pb-9 pt-64 shadow-2xl sm:px-12 lg:max-w-lg lg:px-8 lg:pb-8 xl:px-10 xl:pb-10">
              <img
                alt="Test"
                src={imgUrl}
                className="absolute inset-0 h-full w-full object-cover"
              />
              <div className="absolute bg-gray-900 mix-blend-multiply" />
              <figure className="relative isolate">
                <img alt="" src={testimonialImgUrl} className="h-24 w-auto" />
                <blockquote className="mt-6 text-xl font-semibold leading-8 text-white">
                  <p>{testimonial}</p>
                </blockquote>
                <figcaption className="mt-6 text-sm leading-6 text-gray-300">
                  <strong className="font-semibold text-white">
                    {testimonialBy}
                  </strong>
                </figcaption>
              </figure>
            </div>
          </div>
          <div>
            <div className="text-base leading-7 text-gray-700 lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-mhghDarkBlue-600">
                {subtitle}
              </p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                {title}
              </h1>
              <div className="max-w-xl">
                {text.map((paragraph, idx) => (
                  <p key={idx} className="mt-6">
                    {paragraph}
                  </p>
                ))}
              </div>
              <dl className="mt-10 grid grid-cols-2 gap-8 border-t border-gray-900/10 pt-10 sm:grid-cols-3">
                {stats.map((stat, idx) => (
                  <div key={idx}>
                    <dt className="text-sm font-semibold leading-6 text-gray-600">
                      {stat.label}
                    </dt>
                    <dd className="mt-2 text-3xl font-bold leading-10 tracking-tight text-gray-900">
                      {stat.value}
                    </dd>
                  </div>
                ))}
              </dl>
              {navButton && (
                <div className="mt-10 flex">
                  <a
                    href={linkUrl}
                    className="text-base font-semibold leading-7 text-mhghDarkBlue-600"
                  >
                    {linkText} <span aria-hidden="true">&rarr;</span>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextPlusStatsPlusTestimonial;
