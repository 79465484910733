import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import heroCyclerImg1 from "../../assets/images/heroCycler/heroCycler1.jpg";
import heroCyclerImg2 from "../../assets/images/heroCycler/heroCycler2.jpg";
import heroCyclerImg3 from "../../assets/images/heroCycler/heroCycler3.jpg";
import heroCyclerImg4 from "../../assets/images/heroCycler/heroCycler4.jpg";
import heroCyclerImg5 from "../../assets/images/heroCycler/heroCycler5.jpg";
import heroCyclerImg6 from "../../assets/images/heroCycler/heroCycler6.jpg";
import heroCyclerImg7 from "../../assets/images/heroCycler/heroCycler7.jpg";
import heroCyclerImg8 from "../../assets/images/heroCycler/heroCycler8.jpg";
import heroCyclerImg9 from "../../assets/images/heroCycler/heroCycler9.jpg";
import heroCyclerImg10 from "../../assets/images/heroCycler/heroCycler10.jpg";
import heroCyclerImg11 from "../../assets/images/heroCycler/heroCycler11.jpg";
import heroCyclerImg12 from "../../assets/images/heroCycler/heroCycler12.jpg";
import heroCyclerImg13 from "../../assets/images/heroCycler/heroCycler13.jpg";
import heroCyclerImg14 from "../../assets/images/heroCycler/heroCycler14.jpg";
import heroCyclerImg15 from "../../assets/images/heroCycler/heroCycler15.jpg";
import heroCyclerImg16 from "../../assets/images/heroCycler/heroCycler16.jpg";
import heroCyclerImg17 from "../../assets/images/heroCycler/heroCycler17.jpg";
import heroCyclerImg18 from "../../assets/images/heroCycler/heroCycler18.jpg";
import heroCyclerImg19 from "../../assets/images/heroCycler/heroCycler19.jpg";
import heroCyclerImg20 from "../../assets/images/heroCycler/heroCycler20.jpg";
import heroCyclerImg21 from "../../assets/images/heroCycler/heroCycler21.jpg";
import heroCyclerImg22 from "../../assets/images/heroCycler/heroCycler22.jpg";
import heroCyclerImg23 from "../../assets/images/heroCycler/heroCycler23.jpg";
import heroCyclerImg24 from "../../assets/images/heroCycler/heroCycler24.jpg";
import heroCyclerImg25 from "../../assets/images/heroCycler/heroCycler25.jpg";
import heroCyclerImg26 from "../../assets/images/heroCycler/heroCycler26.jpg";
import heroCyclerImg27 from "../../assets/images/heroCycler/heroCycler27.jpg";
import heroCyclerImg28 from "../../assets/images/heroCycler/heroCycler28.jpg";

import { CSSTransition, TransitionGroup } from "react-transition-group";
import { ReactTyped } from "react-typed";

// Store heroCycler images in an array
const heroCyclerImages = [
  heroCyclerImg1,
  heroCyclerImg2,
  heroCyclerImg3,
  heroCyclerImg4,
  heroCyclerImg5,
  heroCyclerImg6,
  heroCyclerImg7,
  heroCyclerImg8,
  heroCyclerImg9,
  heroCyclerImg10,
  heroCyclerImg11,
  heroCyclerImg12,
  heroCyclerImg13,
  heroCyclerImg14,
  heroCyclerImg15,
  heroCyclerImg16,
  heroCyclerImg17,
  heroCyclerImg18,
  heroCyclerImg19,
  heroCyclerImg20,
  heroCyclerImg21,
  heroCyclerImg22,
  heroCyclerImg23,
  heroCyclerImg24,
  heroCyclerImg25,
  heroCyclerImg26,
  heroCyclerImg27,
  heroCyclerImg28,
];

// Shuffle heroCyclerImages and split into 5 sub-arrays
const shuffledHeroCyclerImages = heroCyclerImages.sort(
  () => Math.random() - 0.5
);

// Select the first 6 images from the shuffled array
const selectedImages = shuffledHeroCyclerImages.slice(0, 6);

export default function Hero() {
  return (
    <div className="bg-white">
      <div className="relative isolate">
        <div className="overflow-hidden">
          <div className="mx-auto max-w-7xl px-6 pb-32 pt-16 sm:pt-24 lg:px-8 lg:pt-16">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:flex lg:max-w-none lg:items-center">
              <div className="relative w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                  We build software for
                  <br />
                  <ReactTyped
                    strings={[
                      "better health.",
                      "the world.",
                      "the future.",
                      "you.",
                      "good.",
                    ]}
                    typeSpeed={75}
                    backSpeed={75}
                    backDelay={1500}
                    loop
                  />
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
                  Welcome to the home of the{" "}
                  <span className="underline">M</span>obile{" "}
                  <span className="underline">H</span>ealth for{" "}
                  <span className="underline">G</span>lobal{" "}
                  <span className="underline">H</span>ealth Lab at Vanderbilt{" "}
                  University! In the MHGH Lab, our research primarily focuses on
                  the development and implementation of software applications in
                  global health settings. We invite you to look around, learn
                  more, check out our code, and get in touch!
                </p>
                <div className="mt-10 flex items-center gap-x-6">
                  <Link
                    to={"#mission"}
                    smooth={true}
                    duration={500}
                    className="rounded-md bg-mhghDarkBlue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-mhghDarkBlue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-mhghDarkBlue-600"
                  >
                    Learn more
                  </Link>
                  <Link
                    to={"/software"}
                    smooth={true}
                    duration={500}
                    className="text-sm font-semibold leading-6 text-mhghBlack-600 hover:text-mhghBlack-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-mhghBlack-800"
                  >
                    See our code <span aria-hidden="true">→</span>
                  </Link>
                </div>
              </div>
              <div className="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
                <div className="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
                  <div className="relative">
                    <TransitionGroup key={"heroCyclerTG1"}>
                      <CSSTransition
                        key={"heroCyclerCSS1"}
                        classNames="image-fade"
                        timeout={{ enter: 1000, exit: 1000 }}
                      >
                        <img
                          src={selectedImages[0]}
                          alt=""
                          className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                        />
                      </CSSTransition>
                    </TransitionGroup>
                    <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                  </div>
                </div>
                <div className="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
                  <div className="relative">
                    <TransitionGroup key={"heroCyclerTG2"}>
                      <CSSTransition
                        key={"heroCyclerCSS2"}
                        classNames="image-fade"
                        timeout={{ enter: 1000, exit: 1000 }}
                      >
                        <img
                          src={selectedImages[1]}
                          alt=""
                          className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                        />
                      </CSSTransition>
                    </TransitionGroup>
                    <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                  </div>
                  <div className="relative">
                    <TransitionGroup key={"heroCyclerTG3"}>
                      <CSSTransition
                        key={"heroCyclerCSS3"}
                        classNames="image-fade"
                        timeout={{ enter: 1000, exit: 1000 }}
                      >
                        <img
                          src={selectedImages[2]}
                          alt=""
                          className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                        />
                      </CSSTransition>
                    </TransitionGroup>
                    <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                  </div>
                </div>
                <div className="w-44 flex-none space-y-8 pt-32 sm:pt-0">
                  <div className="relative">
                    <TransitionGroup key={"heroCyclerTG4"}>
                      <CSSTransition
                        key={"heroCyclerCSS4"}
                        classNames="image-fade"
                        timeout={{ enter: 1000, exit: 1000 }}
                      >
                        <img
                          src={selectedImages[3]}
                          alt=""
                          className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                        />
                      </CSSTransition>
                    </TransitionGroup>
                    <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                  </div>
                  <div className="relative">
                    <TransitionGroup key={"heroCyclerTG5"}>
                      <CSSTransition
                        key={"heroCyclerCSS5"}
                        classNames="image-fade"
                        timeout={{ enter: 1000, exit: 1000 }}
                      >
                        <img
                          src={selectedImages[4]}
                          alt=""
                          className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                        />
                      </CSSTransition>
                    </TransitionGroup>
                    <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
