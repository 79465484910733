import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid';

const SplitPlusImage = ({ title, subtitle, text, points, imageUrl, imageAlt, navButton }) => {
  return (
    <div className="relative bg-white">
      <div className="mx-auto max-w-7xl lg:flex lg:justify-between lg:px-8 xl:justify-end">
        <div className="lg:flex lg:w-1/2 lg:shrink lg:grow-0 xl:absolute xl:inset-y-0 xl:right-1/2 xl:w-1/2">
          <div className="relative h-80 lg:-ml-8 lg:h-auto lg:w-full lg:grow xl:ml-0">
            <img
              alt={imageAlt}
              src={imageUrl}
              className="absolute inset-0 h-full w-full bg-gray-50 object-cover"
            />
          </div>
        </div>
        <div className="px-6 lg:contents">
          <div className="mx-auto max-w-2xl pb-24 pt-16 sm:pb-32 sm:pt-20 lg:ml-8 lg:mr-0 lg:w-full lg:max-w-lg lg:flex-none lg:pt-32 xl:w-1/2">
            <p className="text-base font-semibold leading-7 text-mhghDarkBlue-600">{subtitle}</p>
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{title}</h1>
            <div className="max-w-xl">
              {text.map((paragraph, idx) => (
                <p key={idx} className="mt-6">{paragraph}</p>
              ))}
            </div>
            <ul role="list" className="mt-8 space-y-8 text-gray-600">
              {points.map((point, idx) => (
                <li key={idx} className="flex gap-x-3">
                  <point.icon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-mhghDarkBlue-600" />
                  <span>
                    <strong className="font-semibold text-gray-900">{point.title}</strong> {point.description}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SplitPlusImage;