import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserGraduate } from "@fortawesome/free-solid-svg-icons";
import { LinkIcon } from "@heroicons/react/24/solid";

import { countryCodeToEmoji } from "../shared/utilities";

function Card({ publication }) {
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      {/* Placeholder Image */}
      <img
        src={publication.image} // Placeholder image
        alt="Publication placeholder"
        className="w-full h-48 object-cover"
      />

      <div className="p-4">
        <h2 className="text-lg font-bold">{publication.title}</h2>
        <p className="text-gray-500">{publication.venue}</p>
        <p className="text-gray-400 text-sm">
          {new Date(publication.date).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </p>

        <div className="flex items-center space-x-3 my-2">
          {/* Country flags */}
          <div className="flex space-x-1">
            {publication.countries.map((country) => (
              <span key={country} title={country} className="text-2xl">
                {countryCodeToEmoji(country)}
              </span>
            ))}
          </div>

          {/* Undergraduate Involvement Icon */}
          {publication.undergrad && (
            <FontAwesomeIcon
              icon={faUserGraduate}
              className="h-5 w-5 text-mhghOrange-500"
            />
          )}

          {/* Link to publication */}
          {publication.link && (
            <a
              href={publication.link}
              target="_blank"
              rel="noopener noreferrer"
              className="text-mhghDarkBlue-600 hover:text-mhghDarkBlue-800"
              title="View Publication"
            >
              <LinkIcon className="h-5 w-5" />
            </a>
          )}
        </div>
        {/* Tags as badges */}
        <div className="flex flex-wrap gap-2 mt-2">
          {publication.tags.map((tag) => (
            <span
              key={tag}
              className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-mhghMediumGreen-900 ring-1 ring-inset ring-gray-200"
            >
              <svg
                viewBox="0 0 6 6"
                aria-hidden="true"
                className="h-1.5 w-1.5 fill-mhghMediumGreen-500"
              >
                <circle r={3} cx={3} cy={3} />
              </svg>
              {tag}
            </span>
          ))}
        </div>
        {/* Diseases as badges */}
        <div className="flex flex-wrap gap-2 mt-2">
          {publication.disease.map((disease) => (
            <span
              key={disease}
              className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-mhghLightBlue-900 ring-1 ring-inset ring-gray-200"
            >
              <svg
                viewBox="0 0 6 6"
                aria-hidden="true"
                className="h-1.5 w-1.5 fill-mhghLightBlue-500"
              >
                <circle r={3} cx={3} cy={3} />
              </svg>
              {disease}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Card;
