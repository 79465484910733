import Hero from "../components/Home/Hero";
import Mission from "../components/Home/Mission";
import Team from "../components/Home/Team";
import Contact from "../components/Home/Contact";
import LogoCloud from "../components/Home/LogoCloud";

export default function Home() {
  return (
    <div id="top">
      <section id="home">
        <Hero />
      </section>
      <section id="mission">
        <Mission />
      </section>
      <section id="team">
        <Team />
      </section>
      <section id="logo">
        <LogoCloud />
      </section>
      <section id="contact">
        <Contact />
      </section>
    </div>
  );
}
