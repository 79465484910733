import niaidLogo from '../../assets/logos/logoCloud/niaidLogo.png';
import fogartyLogo from '../../assets/logos/logoCloud/fogartyLogo.png';
import nsfLogo from '../../assets/logos/logoCloud/nsfLogo.png';
import burroughsLogo from '../../assets/logos/logoCloud/burroughsWellcomeFundLogo.png';
import awsLogo from '../../assets/logos/logoCloud/awsLogo.png';

export default function LogoCloud() {
    return (
      <div className="bg-gray-900 py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:max-w-none">
            <h2 className="text-lg font-semibold leading-8 text-white">We are grateful to receive support for our research from:</h2>
            <div className="mx-auto mt-10 grid grid-cols-4 items-start gap-x-8 gap-y-10 sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:grid-cols-5">
              <img
                alt="NIH/NIAID"
                src={niaidLogo}
                width={158}
                height={48}
                className="col-span-2 max-h-12 w-full object-contain object-center lg:col-span-1 filter brightness-0 invert"
                />
              <img
                alt="NIH/Fogary"
                src={fogartyLogo}
                width={158}
                height={48}
                className="col-span-2 max-h-12 w-full object-contain object-center lg:col-span-1 filter brightness-0 invert"
                />
              <img
                alt="NSF"
                src={nsfLogo}
                width={158}
                height={48}
                className="col-span-2 max-h-12 w-full object-contain object-center lg:col-span-1 filter brightness-0 invert"
              />
              <img
                alt="Burroughs Wellcome Fund"
                src={burroughsLogo}
                width={158}
                height={48}
                className="col-span-2 max-h-12 w-full object-contain object-center lg:col-span-1 filter brightness-0 invert"
                />
              <img
                alt="Amazon Web Services"
                src={awsLogo}
                width={158}
                height={48}
                className="col-span-2 max-h-12 w-full object-contain object-center lg:col-span-1 filter brightness-0 invert"
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
  