// tag options: biofluids, mass transfer, diagnostics, education, mHealth, disease surveillance, global health, standardization, image analysis
// disease options: hiv, malaria, covid-19, schistosomiasis

import orbitalMixer2016 from "../../assets/images/publications/2016-a-handheld-orbital.jpg";
import lfaEducation2022 from "../../assets/images/publications/2022-incorporating-lateral-flow.jpg";
import mlabUsability2021 from "../../assets/images/publications/2021-usability-evaluation-of.jpg";
import mhat2021 from "../../assets/images/publications/2021-mHAT-app-for.jpg";
import randomizedControlled2021 from "../../assets/images/publications/2021-a-randomized-controlled-trial.jpg";
import understandingOnCampus2020 from "../../assets/images/publications/2020-understanding-on-campus-interactions.jpg";
import evaluatingNetwork2020 from "../../assets/images/publications/2020-evaluating-network-readiness.jpg";
import appUseAndUsability2020 from "../../assets/images/publications/2020-app-use-and-usability.jpg";
import aTwoMagnetStrategy2016 from "../../assets/images/publications/2016-a-two-magnet-strategy.jpg";
import magneticallyEnabled2017 from "../../assets/images/publications/2017-magnetically-enabled-biomarker-extraction.jpg";
import applicationOfMass2017 from "../../assets/images/publications/2017-application-of-mass.jpg";
import anEmbeddedBarcode2017 from "../../assets/images/publications/2017-an-embedded-barcode.jpg";
import mobilePhoneImaging2016 from "../../assets/images/publications/2016-mobile-phone-imaging-and.jpg";
import directTransfer2016 from "../../assets/images/publications/2016-direct-transfer-of-hrpii.jpg";
import immunochromatographicPocCca2016 from "../../assets/images/publications/2016-immunochromatographic-poc-cca.jpg";
import heardLibraries2024 from "../../assets/images/publications/2024-heard-libraries-digital-lab.jpg";
import vanderbiltScreening2020 from "../../assets/images/publications/2020-vanderbilt-university-screening-tool.jpg";
import assessingPreferences2024 from "../../assets/images/publications/2024-assessing-preferences-for.jpg";
import inductivelyCoupled2021 from "../../assets/images/publications/2021-inductively-coupled-plasma.jpg";

const publicationList = [
  {
    title:
      "A handheld orbital mixer for processing viscous samples in low resource settings",
    collection: "publications",
    permalink: "/publication/2016-a-handheld-orbital",
    date: "2016-09-01",
    venue: "Analytical Methods",
    image: orbitalMixer2016,
    link: "https://pubs.rsc.org/en/content/articlelanding/2016/ay/c6ay01636g/unauth",
    code: "",
    github: "",
    citation:
      "Scherr, T.F. et al. (2016) ‘A handheld orbital mixer for processing viscous samples in low resource settings’, Analytical Methods, 8(40), pp. 7347–7353. doi:10.1039/c6ay01636g.",
    undergrad: true,
    countries: ["us"],
    tags: ["biofluids", "mass transfer", "global health"],
    disease: [],
  },
  {
    title:
      "Incorporating Lateral Flow Assays into Undergraduate Analytical Chemistry Lab Curricula for In-Person, Hybrid, and Remote Learning Formats",
    collection: "publications",
    permalink: "/publication/2022-incorporating-lateral-flow",
    date: "2022-01-26",
    venue: "Journal of Chemical Education",
    image: lfaEducation2022,
    link: "https://pubs.acs.org/doi/abs/10.1021/acs.jchemed.1c00088",
    code: "",
    github: "",
    citation:
      "Andrew G. Kantor, Thomas Foster Scherr, David W. Wright, and Susan Denise Verberne-Sutton. Incorporating Lateral Flow Assays into Undergraduate Analytical Chemistry Lab Curricula for In-Person, Hybrid, and Remote Learning Formats. Journal of Chemical Education 2022 99 (2), 902-909. DOI: 10.1021/acs.jchemed.1c00088",
    undergrad: false,
    countries: ["us"],
    tags: ["diagnostics", "education", "mHealth"],
    disease: [],
  },
  {
    title:
      "Usability Evaluation of the mLab App for Improving Home HIV Testing Behaviors in Youth at Risk of HIV Infection",
    collection: "publications",
    permalink: "/publication/2021-usability-evaluation-of",
    date: "2021-08-01",
    venue: "AIDS Education and Prevention",
    image: mlabUsability2021,
    link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8487399/",
    code: "",
    github: "",
    citation:
      "Sanabria G, Scherr T, Garofalo R, Kuhns LM, Bushover B, Nash N, Davis R, Schnall R. Usability Evaluation of the mLab App for Improving Home HIV Testing Behaviors in Youth at Risk of HIV Infection. AIDS Educ Prev. 2021 Aug;33(4):312-324. doi: 10.1521/aeap.2021.33.4.312. PMID: 34370566; PMCID: PMC8487399.",
    undergrad: false,
    countries: ["us"],
    tags: ["mHealth", "usability"],
    disease: ["hiv"],
  },
  {
    title:
      "mHAT app for automated malaria rapid test result analysis and aggregation: a pilot study",
    collection: "publications",
    permalink: "/publication/2021-mHAT-app-for",
    date: "2021-05-26",
    venue: "Malaria Journal",
    image: mhat2021,
    link: "https://doi.org/10.1186/s12936-021-03772-5",
    code: "",
    github: "",
    citation:
      "Moore, C., Scherr, T., Matoba, J. et al. mHAT app for automated malaria rapid test result analysis and aggregation: a pilot study. Malar J 20, 237 (2021).",
    undergrad: false,
    countries: ["us", "zm"],
    tags: ["mHealth", "diagnostics", "disease surveillance", "global health"],
    disease: ["malaria"],
  },
  {
    title:
      "A randomized controlled trial of an mHealth intervention for increasing access to HIV testing and care among young cisgender men and transgender women: the mLab App study protocol",
    collection: "publications",
    permalink: "/publication/2021-a-randomized-controlled-trial",
    date: "2021-10-29",
    venue: "BMC Public Health",
    image: randomizedControlled2021,
    link: "https://doi.org/10.1186/s12889-021-12015-w",
    code: "",
    github: "",
    citation:
      "Wood, O.R., Garofalo, R., Kuhns, L.M. et al. A randomized controlled trial of an mHealth intervention for increasing access to HIV testing and care among young cisgender men and transgender women: the mLab App study protocol. BMC Public Health 21, 1959 (2021).",
    undergrad: false,
    countries: ["us"],
    tags: ["diagnostics", "mHealth"],
    disease: ["hiv"],
  },
  {
    title:
      "Understanding On-Campus Interactions With a Semiautomated, Barcode-Based Platform to Augment COVID-19 Contact Tracing: App Development and Usage",
    collection: "publications",
    permalink: "/publication/2020-understanding-on-campus-interactions",
    date: "2020-09-11",
    venue: "JMIR mHealth and uHealth",
    image: understandingOnCampus2020,
    link: "https://mhealth.jmir.org/2021/3/e24275",
    code: "",
    github: "",
    citation:
      "Scherr T, Hardcastle A, Moore C, DeSousa J, Wright D. Understanding On-Campus Interactions With a Semiautomated, Barcode-Based Platform to Augment COVID-19 Contact Tracing: App Development and Usage. JMIR Mhealth Uhealth. 2021;9(3):e24275. DOI: 10.2196/24275",
    undergrad: false,
    countries: ["us"],
    tags: ["mHealth", "disease surveillance", "usability"],
    disease: ["covid-19"],
  },
  {
    title:
      "Evaluating Network Readiness for mHealth Interventions Using the Beacon Mobile Phone App: Application Development and Validation Study",
    collection: "publications",
    permalink: "/publication/2020-evaluating-network-readiness",
    date: "2020-02-25",
    venue: "JMIR mHealth and uHealth",
    image: evaluatingNetwork2020,
    link: "https://mhealth.jmir.org/2020/7/e18413",
    code: "",
    github: "",
    citation:
      "Scherr TF, Moore CP, Thuma P, Wright DW. Evaluating Network Readiness for mHealth Interventions Using the Beacon Mobile Phone App: Application Development and Validation Study.JMIR Mhealth Uhealth 2020;8(7):e18413.",
    undergrad: false,
    countries: ["us", "zm"],
    tags: ["mHealth", "global health"],
    disease: [],
  },
  {
    title:
      "App Use and Usability of a Barcode-Based Digital Platform to Augment COVID-19 Contact Tracing: Postpilot Survey and Paradata Analysis",
    collection: "publications",
    permalink: "/publication/2020-app-use-and-usability",
    date: "2020-11-20",
    venue: "JMIR mHealth and uHealth",
    image: appUseAndUsability2020,
    link: "https://publichealth.jmir.org/2021/3/e25859",
    code: "",
    github: "",
    citation:
      "Scherr T, DeSousa J, Moore C, Hardcastle A, Wright D. App Use and Usability of a Barcode-Based Digital Platform to Augment COVID-19 Contact Tracing: Postpilot Survey and Paradata Analysis. JMIR Public Health Surveill. 2021;7(3):e25859. DOI: 10.2196/25859",
    undergrad: false,
    countries: ["us"],
    tags: ["mHealth", "disease surveillance", "usability"],
    disease: ["covid-19"],
  },
  {
    title:
      "Magnetically-enabled biomarker extraction and delivery system: towards integrated ASSURED diagnostic tools",
    collection: "publications",
    permalink: "/publication/2017-magnetically-enabled-biomarker-extraction",
    date: "2017-05-02",
    venue: "Analyst",
    image: magneticallyEnabled2017,
    link: "https://pubs.rsc.org/en/content/articlelanding/2017/an/c7an00278e",
    code: "",
    github: "",
    citation:
      "Bauer WS, Kimmel DW, Adams NM, Gibson LE, Scherr TF, Richardson KA, Conrad JA, Matakala HK, Haselton FR, Wright DW. Magnetically-enabled biomarker extraction and delivery system: towards integrated ASSURED diagnostic tools. Analyst. 2017 May 2;142(9):1569-1580. doi: 10.1039/c7an00278e. PMID: 28386613; PMCID: PMC5674985.",
    undergrad: false,
    countries: ["us", "zm"],
    tags: ["diagnostics"],
    disease: ["malaria"],
  },
  {
    title:
      "Application of mass transfer theory to biomarker capture by surface functionalized magnetic beads in microcentrifuge tubes",
    collection: "publications",
    permalink: "/publication/2017-application-of-mass",
    date: "2017-02-17",
    venue: "Advances in Colloid and Interface Science",
    image: applicationOfMass2017,
    link: "https://www.sciencedirect.com/science/article/abs/pii/S0001868616302664",
    code: "",
    github: "",
    citation:
      "Thomas F. Scherr, Christine F. Markwalter, Westley S. Bauer, David Gasperino, David W. Wright, Frederick R. Haselton. Application of mass transfer theory to biomarker capture by surface functionalized magnetic beads in microcentrifuge tubes. Advances in Colloid and Interface Science, Volume 246, 2017, Pages 275-288, ISSN 0001-8686, doi.org/10.1016/j.cis.2017.02.006.",
    undergrad: false,
    countries: ["us"],
    tags: ["biofluids", "mass transfer"],
    disease: [],
  },
  {
    title: 'An embedded barcode for "connected" malaria rapid diagnostic tests',
    collection: "publications",
    permalink: "/publication/2017-an-embedded-barcode",
    date: "2017-03-29",
    venue: "Lab on a Chip",
    image: anEmbeddedBarcode2017,
    link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6474256/",
    code: "",
    github: "",
    citation:
      'Scherr TF, Gupta S, Wright DW, Haselton FR. An embedded barcode for "connected" malaria rapid diagnostic tests. Lab Chip. 2017 Mar 29;17(7):1314-1322. doi: 10.1039/c6lc01580h. PMID: 28252130; PMCID: PMC6474256.',
    undergrad: true,
    countries: ["us"],
    tags: ["diagnostics", "mHealth"],
    disease: ["malaria"],
  },
  {
    title:
      "Mobile phone imaging and cloud-based analysis for standardized malaria detection and reporting",
    collection: "publications",
    permalink: "/publication/2016-mobile-phone-imaging-and",
    date: "2016-06-27",
    venue: "Scientific Reports",
    image: mobilePhoneImaging2016,
    link: "https://doi.org/10.1038/srep28645",
    code: "",
    github: "",
    citation:
      "Scherr, T., Gupta, S., Wright, D. et al. Mobile phone imaging and cloud-based analysis for standardized malaria detection and reporting. Sci Rep 6, 28645 (2016). https://doi.org/10.1038/srep28645",
    undergrad: true,
    countries: ["us"],
    tags: ["diagnostics", "mHealth", "standardization"],
    disease: ["malaria"],
  },
  {
    title:
      "Direct transfer of HRPII-magnetic bead complexes to malaria rapid diagnostic tests significantly improves test sensitivity",
    collection: "publications",
    permalink: "/publication/2016-direct-transfer-of-hrpii",
    date: "2016-08-05",
    venue: "Malaria Journal",
    image: directTransfer2016,
    link: "https://doi.org/10.1186/s12936-016-1448-6",
    code: "",
    github: "",
    citation:
      "Ricks, K.M., Adams, N.M., Scherr, T.F. et al. Direct transfer of HRPII-magnetic bead complexes to malaria rapid diagnostic tests significantly improves test sensitivity. Malar J 15, 399 (2016). https://doi.org/10.1186/s12936-016-1448-6",
    undergrad: false,
    countries: ["us"],
    tags: ["diagnostics"],
    disease: ["malaria"],
  },
  {
    title:
      "A two-magnet strategy for improved mixing and capture from biofluids",
    collection: "publications",
    permalink: "/publication/2016-a-two-magnet-strategy",
    date: "2016-04-11",
    venue: "Biomicrofluidics",
    image: aTwoMagnetStrategy2016,
    link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4833749/",
    code: "",
    github: "",
    citation:
      "Scherr TF, Ryskoski HB, Doyle AB, Haselton FR. A two-magnet strategy for improved mixing and capture from biofluids. Biomicrofluidics. 2016 Apr 11;10(2):024118. doi: 10.1063/1.4946014. PMID: 27158286; PMCID: PMC4833749.",
    undergrad: true,
    countries: ["us"],
    tags: ["biofluids", "mass transfer"],
    disease: [],
  },
  {
    title:
      "Immunochromatographic POC-CCA Test for the diagnosis of intestinal schistosomiasis in a high endemic region in Brazil: Differences in the interpretation of results",
    collection: "publications",
    permalink: "/publication/2016-immunochromatographic-poc-cca",
    date: "2024-06-01",
    venue: "Acta Tropica",
    image: immunochromatographicPocCca2016,
    link: "https://www.sciencedirect.com/science/article/abs/pii/S0001706X24000652",
    code: "",
    github: "",
    citation:
      "de Carvalho RG, Pinheiro MCC, Morais FJD, Galvão RLF, Barbosa L, Sá SLCS, Scherr TF, Bezerra FSM. Immunochromatographic POC-CCA Test for the diagnosis of intestinal schistosomiasis in a high endemic region in Brazil: Differences in the interpretation of results. Acta Tropica, Volume 254, 2024, 107181. https://doi.org/10.1016/j.actatropica.2024.107181.",
    undergrad: false,
    countries: ["us", "br"],
    tags: ["diagnostics", "global health"],
    disease: ["schistosomiasis"],
  },
  {
    title:
      "Heard Libraries’ Digital Lab awards seed grants to four emerging digital initiatives",
    collection: "press",
    permalink: "",
    date: "2024-07-01",
    venue: "Library News Online",
    image: heardLibraries2024,
    link: "https://newsonline.library.vanderbilt.edu/2024/06/heard-libraries-digital-lab-awards-seed-grants-to-four-emerging-digital-initiatives/",
    code: "",
    github: "",
    citation: "",
    undergrad: true,
    countries: ["us"],
    tags: ["image analysis"],
    disease: [],
  },
  {
    title: "Vanderbilt University screening tool assesses COVID-19 risk",
    collection: "press",
    permalink: "",
    date: "2020-06-01",
    venue: "Research News Online",
    image: vanderbiltScreening2020,
    link: "https://news.vanderbilt.edu/2020/06/01/vanderbilt-university-screening-tool-assesses-covid-19-risk/",
    code: "",
    github: "",
    citation: "",
    undergrad: false,
    countries: ["us"],
    tags: ["disease surveillance"],
    disease: ["covid-19"],
  },
  {
    title:
      "Assessing Preferences for Long-Acting Injectable Pre-Exposure Prophylaxis Among Young Adult Sexual Minority Men and Transgender Women",
    collection: "publications",
    permalink: "",
    date: "2024-04-15",
    venue: "Academic Pediatrics",
    image: assessingPreferences2024,
    link: "https://www.sciencedirect.com/science/article/abs/pii/S1876285924001487",
    code: "",
    github: "",
    citation:
      "Weeden T, Garofalo R, Johnson AK, Schnall R, Cervantes M, Scherr T, Kuhns LM. Assessing Preferences for Long-Acting Injectable Pre-Exposure Prophylaxis Among Young Adult Sexual Minority Men and Transgender Women. Academic Pediatrics, Volume 24, Issue 7, 2024, Pages 1110-1115. https://doi.org/10.1016/j.acap.2024.04.005.",
    undergrad: false,
    countries: ["us"],
    tags: ["mHealth"],
    disease: ["hiv"],
  },
  {
    title:
      "Inductively coupled plasma optical emission spectroscopy as a tool for evaluating lateral flow assays",
    collection: "publications",
    permalink: "",
    date: "2021-04-11",
    venue: "Analytical Methods",
    image: inductivelyCoupled2021,
    link: "https://pubs.rsc.org/en/content/articlelanding/2021/ay/d1ay00236h/unauth",
    code: "",
    github: "",
    citation:
      "DeSousa JM, Jorge MZ, Lindsay HB, Haselton FR, Wright DW, Scherr TF. Inductively coupled plasma optical emission spectroscopy as a tool for evaluating lateral flow assays. Anal. Methods, 2021,13, 2137-2146. https://doi.org/10.1039/D1AY00236H.",
    undergrad: true,
    countries: ["us"],
    tags: ["diagnostics"],
    disease: [],
  },
];

export default publicationList;
