import React, { useState } from "react";

const SolarU = () => {
  return (
    <div style={styles.container}>
      <section id="privacy" style={styles.section}>
        <h1 style={styles.heading}>Privacy Policy</h1>
        <p style={styles.text}>
          At SolarU, your privacy is our top priority. This Privacy Policy
          outlines how we collect, use, and protect your information when you
          use our app.
        </p>

        <h2 style={styles.subheading}>Information We Collect</h2>
        <p style={styles.text}>
          SolarU collects the following information to provide a better
          experience:
        </p>
        <ul style={styles.list}>
          <li>NASA solar insolation data based on your location</li>
          <li>Mood and energy levels that you log within the app</li>
          <li>
            Optional health data (e.g., step count, heart rate) with your
            explicit consent
          </li>
        </ul>

        <h2 style={styles.subheading}>How We Use Your Information</h2>
        <p style={styles.text}>
          The information collected is used solely for the following purposes:
        </p>
        <ul style={styles.list}>
          <li>To correlate solar exposure with mood and energy trends</li>
          <li>To provide insights and visualizations for your well-being</li>
          <li>To improve SolarU’s functionality and user experience</li>
        </ul>

        <h2 style={styles.subheading}>Data Sharing and Security</h2>
        <p style={styles.text}>
          We do not share your personal information with third parties without
          your consent. SolarU uses advanced encryption and data security
          protocols to ensure your information is protected.
        </p>
      </section>
      <section id="accountmanagement" style={styles.section}>
        <h1 style={styles.heading}>Account Management</h1>

        <div>
          <p style={styles.text}>
            At SolarU, we value your privacy and give you control over your
            account. If you wish to delete your account, you can submit a
            request to thomas.f.scherr@vanderbilt.edu. Once processed:
          </p>
          <ul style={styles.list}>
            <li>
              Your personal data will be permanently removed from our systems.
            </li>
            <li>
              Any research data associated with your account will be anonymized
              and retained for analysis to further our scientific understanding.
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};

const styles = {
  container: {
    padding: "20px",
    fontFamily: "Arial, sans-serif",
    lineHeight: "1.6",
    color: "#333",
    backgroundColor: "#f9f9f9",
    borderRadius: "8px",
    maxWidth: "800px",
    margin: "20px auto",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  nav: {
    marginBottom: "20px",
    display: "flex",
    gap: "10px",
  },
  navLink: {
    textDecoration: "none",
    color: "#d32f2f",
    fontWeight: "bold",
    borderBottom: "2px solid transparent",
  },
  navLinkHover: {
    borderBottom: "2px solid #d32f2f",
  },
  section: {
    marginBottom: "40px",
  },
  heading: {
    fontSize: "2em",
    color: "#d32f2f",
    marginBottom: "20px",
  },
  text: {
    marginBottom: "15px",
  },
  list: {
    marginBottom: "20px",
    paddingLeft: "20px",
    listStyleType: "disc",
  },
  button: {
    backgroundColor: "#d32f2f",
    color: "#fff",
    border: "none",
    padding: "10px 20px",
    fontSize: "1em",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

export default SolarU;
