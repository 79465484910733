export default function TeamSelector({
  onRoleFilterChange,
  onStatusFilterChange,
  roleFilter,
  statusFilter,
}) {
  return (
    <div className="flex space-x-4">
      <span className="isolate inline-flex rounded-md shadow-sm">
        <button
          type="button"
          className={`relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10 
            ${roleFilter === 'pi' 
              ? 'bg-mhghLightGreen-100 text-mhghBlack-700 hover:bg-mhghLightGreen-200'
              : 'bg-white text-mhghBlack-900 hover:bg-gray-50'}`}
          onClick={() => onRoleFilterChange(roleFilter === 'pi' ? '' : 'pi')}
        >
          Principal Investigator
        </button>
        <button
          type="button"
          className={`relative -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10 
            ${roleFilter === 'staff' 
              ? 'bg-mhghLightGreen-100 text-mhghBlack-700 hover:bg-mhghLightGreen-200' 
              : 'bg-white text-mhghBlack-900 hover:bg-gray-50'}`}
          onClick={() => onRoleFilterChange(roleFilter === 'staff' ? '' : 'staff')}
        >
          Staff
        </button>
        <button
          type="button"
          className={`relative -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10 
            ${roleFilter === 'postdoc' 
              ? 'bg-mhghLightGreen-100 text-mhghBlack-700 hover:bg-mhghLightGreen-200' 
              : 'bg-white text-mhghBlack-900 hover:bg-gray-50'}`}
          onClick={() => onRoleFilterChange(roleFilter === 'postdoc' ? '' : 'postdoc')}
        >
          Postdocs
        </button>
        <button
          type="button"
          className={`relative -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10 
            ${roleFilter === 'graduate' 
              ? 'bg-mhghLightGreen-100 text-mhghBlack-700 hover:bg-mhghLightGreen-200' 
              : 'bg-white text-mhghBlack-900 hover:bg-gray-50'}`}
          onClick={() => onRoleFilterChange(roleFilter === 'graduate' ? '' : 'graduate')}
        >
          Grad Students
        </button>
        <button
          type="button"
          className={`relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10 
            ${roleFilter === 'undergraduate' 
              ? 'bg-mhghLightGreen-100 text-mhghBlack-700 hover:bg-mhghLightGreen-200' 
              : 'bg-white text-mhghBlack-900 hover:bg-gray-50'}`}
          onClick={() => onRoleFilterChange(roleFilter === 'undergraduate' ? '' : 'undergraduate')}
        >
          Undergrads
        </button>
      </span>
      <span className="isolate inline-flex rounded-md shadow-sm">
        <button
          type="button"
          className={`relative inline-flex rounded-l-md items-center px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10 
            ${statusFilter === 'active' 
              ? 'bg-mhghLightGreen-100 text-mhghBlack-700 hover:bg-mhghLightGreen-200' 
              : 'bg-white text-mhghBlack-900 hover:bg-gray-50'}`}
          onClick={() => onStatusFilterChange(statusFilter === 'active' ? '' : 'active')}
        >
          Active
        </button>
        <button
          type="button"
          className={`relative -ml-px inline-flex rounded-r-md items-center px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10 
            ${statusFilter === 'alum' 
              ? 'bg-mhghLightGreen-100 text-mhghBlack-700 hover:bg-mhghLightGreen-200' 
              : 'bg-white text-mhghBlack-900 hover:bg-gray-50'}`}
          onClick={() => onStatusFilterChange(statusFilter === 'alum' ? '' : 'alum')}
        >
          Alum
        </button>
      </span>
    </div>
  );
}
