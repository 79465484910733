import TwoColPlusScreenshot from "../components/Research/TwoColPlusScreenshot";
import OneColPlusScreenshot from "../components/Research/OneColPlusScreenshot";
import SplitPlusImage from "../components/Research/SplitPlusImage";
import TextPlusStatsPlusTestimonial from "../components/Research/TextPlusStatsPlusTestimonial";
import TextPlusStats from "../components/Research/TextPlusStats";
import earthCode from "../assets/images/research/earthCode.png";
import codeGrab from "../assets/images/research/codeGrab.png";
import drPhone from "../assets/images/research/drPhone.jpg";
import epiMap from "../assets/images/research/epiMap.jpg";
import eppTubes from "../assets/images/research/eppTubes.jpg";
import phoneSteth from "../assets/images/research/phoneSteth.jpg";
// import FloatingNavMenu from "../components/Research/FloatingNavMenu";

import {
  CloudArrowUpIcon,
  LockClosedIcon,
  ServerIcon,
} from "@heroicons/react/20/solid";


export default function Research() {
  return (
    <>
    {/* <FloatingNavMenu /> */}
    <div id="top">
      <section id="overview">
        <OneColPlusScreenshot
          title="MHGH Lab Overview"
          subtitle="Mobile & Global Health Software"
          text={[
            "The MHGH Lab has a demonstrated track record of success operating at the unique interface of mobile and global health software development. We have ongoing collaborations outside of the Chemistry Department at Vanderbilt (Biomedical Engineering, Computer Science, Data Science Institute, Medical Center, Institute for Global Health), as well as beyond campus (Columbia University, Lurie’s Children’s Hospital in Chicago, Oxford University, Leiden University Medical Center, the Kenya Medical Research Institute, the Macha Research Trust). Our full-stack mHealth applications have solved problems in infectious disease surveillance (e.g., malaria, HIV, COVID-19, schistosomiasis), global health logistics (e.g., personnel, resource, scheduling management), data science (e.g., creation of “data-rich” environments with data capture apps, large-scale structured and unstructured data analysis, machine learning and model development), and mobile health infrastructure. We have received research support from the National Science Foundation, the National Institutes of Health, the Tennessee Center for AIDS Research, the Burroughs Wellcome Fund, Amazon Web Services, and Google Cloud. Software from our lab has been deployed domestically in the US and globally.",
          ]}
          linkText="Learn more about MHGH Lab"
          linkUrl="#"
          imageUrl={earthCode}
          imageAlt="Overview of MHGH Lab's collaborations and projects"
          navButton={false}
        />
      </section>

      <section id="connected-dx">
        <SplitPlusImage
          title="Connected Diagnostics"
          subtitle="Improving Point-of-Care Testing"
          text={[
            "One of our more advanced research initiatives is centered on the concept of “connected” diagnostics. One significant issue in the field of diagnostics is that point-of-care tests are made to be inexpensive and portable, but this comes with drawbacks that can be readily addressed by mobile devices. First, visual inspection, the most common approach for determining the result of a point-of-care diagnostic test, is subjective and ambiguous. This is particularly true of tests that are designed to be semi-quantitative. Lastly, the results from these tests have no integration into electronic health records or epidemiology surveillance platforms — they rely on manual reporting of the results, which is slow and error prone.",
            "Through a combination of novel digital image processing algorithms, app development, and cloud software design, we have addressed each of these challenges. The web app version of our software, mLab, can be accessed on any device with a web browser, and can interpret point-of-care test results for a variety of infectious diseases (e.g., malaria, COVID-19, schistosomiasis, and HIV). In addition, we have developed native mobile applications (i.e., iOS and Android) for regions where network connectivity is intermittent or non-existent.",
            "Finally, we have begun to integrate features that more closely couple mobile devices into the diagnostic tests. Primarily, this has been through patterning assay bioreagents in the shape of barcodes directly onto the flow membrane. This allows mobile devices to natively recognize the test zone of the diagnostic test, and incorporates both standardization and security, improves quantification, and enables higher-degrees of multiplexing than what is currently attainable on available diagnostic tests.",
          ]}
          points={[
            {
              title: "Mobile Devices",
              description:
                "Mobile devices address the subjectivity of visual test inspection.",
              icon: CloudArrowUpIcon,
            },
            {
              title: "Cloud Integration",
              description:
                "Results are seamlessly integrated into electronic health records and epidemiological platforms.",
              icon: ServerIcon,
            },
          ]}
          imageUrl={phoneSteth}
          imageAlt="Connected Diagnostics using Mobile Technology"
          navButton={false}
        />
      </section>

      <section id="epi-infra">
        <TextPlusStatsPlusTestimonial
          title="Epidemiology Surveillance Infrastructure"
          subtitle="Holistic Approach to Infectious Disease Surveillance"
          text={[
            "Infectious disease surveillance infrastructure is often composed of fragmented systems with poor interoperability. There are software platforms for individual end-users, but these do not integrate with higher-level, national-scale surveillance programs. Meanwhile, national-scale surveillance software often fails to provide meaningful insight and can cause considerable lag between data stream updates, thus delaying critical action in the face of disease outbreaks. In low- and middle-income countries (LMICs), many of these platforms are legacy systems that were selected by governments and NGOs long ago because they were the best option available at the time, and their continued usage is hindered by their limited feature set and poor usability. In the US, the competition between systems is strong which results in improved but proprietary features that usually don’t communicate with each other. Our lab is working on improved software platforms that provide the right information to the right people at the right time. Our software in this space takes a holistic approach, integrating components for patients/end-users, field healthcare teams, infectious disease program managers, funding organizations, and government officials. This work started with the development and deployment of COVID-19 symptom checkers, which were integrated with diagnostic testing and healthcare resource management, and eventually added in a full suite of contact tracing tools. We continue to pursue opportunities in this space, agnostic of disease and geography.",
          ]}
          stats={[
            { label: "Deployed Projects", value: "9" },
            { label: "Countries", value: "7" },
            { label: "Total Users", value: "250+" },
          ]}
          testimonial=""
          imgUrl={epiMap}
          testimonialImgUrl={null}
          testimonialBy=""
          linkText="Learn more about our surveillance software"
          linkUrl="#"
          navButton={false}
        />
      </section>

      {/* <section id="pred-model">
        <TextPlusStats
          title="Machine Learning for Predictive Modeling"
          subtitle="Predicting Infectious Disease Outcomes"
          text={[
            "One thing that we’ve learned is that infectious disease trajectory and outcomes can be predicted. If you have enough data describing what is currently happening in a certain place, machine learning models can identify other locations where a similar outcome will occur. Given our ongoing efforts in high-resolution infectious disease surveillance data collection, it makes sense for us to also leverage that data for predictive modeling.",
          ]}
          stats={[
            { label: "Model Accuracy", value: "90%" },
            { label: "Countries Monitored", value: "15+" },
            { label: "Models Deployed", value: "50+" },
          ]}
          linkText="Explore our machine learning models"
          linkUrl={eppTubes}
          navButton={false}
        />
      </section> */}

      <section id="prog-mgmt">
        <TwoColPlusScreenshot
          title="Program Management Software"
          subtitle="Automating Research Field Trial Management"
          text={[
            "Managing a research field trial goes beyond just the science. Principal Investigators and field trial managers have to become experts in logistics planning – monitoring stocks and locations of critical inventory, understanding personnel workloads and performance, and data collection/aggregation/analysis — all while staying under a strict operating budget with a hard ceiling. This logistics management is even more challenging in LMICs, where plans must be fluid due to unexpected events and unplanned disruptions. Our group is building software that manages and sometimes automates field trial management.",
          ]}
          linkText="See how we manage field trials"
          linkUrl="#"
          imageUrl={codeGrab}
          imageAlt="Program Management Software Interface"
          navButton={false}
        />
      </section>

      <section id="mhealth-usability">
        <SplitPlusImage
          title="mHealth Usability"
          subtitle="Understanding User Behavior in Mobile Health Apps"
          text={[
            "With the surge in popularity in mobile applications for healthcare, there has been a growing effort to understand why certain applications see successful user adoption and others do not. We work closely with colleagues that perform focus groups to understand motivators and deterrents for using features of, or entire, mobile health applications. While we occasionally take on that role ourselves, our primary contribution to this research is in building ways to observe and analyze user behavior from within mHealth apps. Primarily to date, this has stemmed from a behind-the-scenes, automatic paradata collection library. This library allows for the analysis of user workflows within an app, grouping together users for analysis based on actions taken or outcomes within an app, and correlation between quantitative and semi-quantitative feedback.",
          ]}
          points={[
            {
              title: "User Behavior Tracking",
              description:
                "Automatically track in-app user workflows and behaviors.",
              icon: LockClosedIcon,
            },
            {
              title: "Improved Usability",
              description:
                "Analyze and improve app features based on user feedback and interaction data.",
              icon: ServerIcon,
            },
          ]}
          imageUrl={drPhone}
          imageAlt="Mobile health app usability"
          navButton={false}
        />
      </section>
    </div>
    </>
  );
}
