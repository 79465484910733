const TwoColPlusScreenshot = ({
  title,
  subtitle,
  text,
  linkText,
  linkUrl,
  imageUrl,
  imageAlt,
  navButton,
}) => {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
          <p className="text-base font-semibold leading-7 text-mhghDarkBlue-600">
            {subtitle}
          </p>
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {title}
          </h1>
          <div className="mt-10 grid max-w-xl grid-cols-1 gap-8 text-base leading-7 text-gray-700 lg:max-w-none lg:grid-cols-2">
            <div>
              <div className="max-w-xl">
                {text.map((paragraph, idx) => (
                  <p key={idx} className="mt-6">
                    {paragraph}
                  </p>
                ))}
              </div>
            </div>
          </div>
          {navButton && 
          <div className="mt-10 flex">
            <a
              href={linkUrl}
              className="rounded-md bg-mhghDarkBlue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-mhghDarkBlue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-mhghDarkBlue-600"
            >
              {linkText}
            </a>
          </div>
          }
        </div>
      </div>
      <div className="relative overflow-hidden pt-16 lg:pt-20">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <img
            alt={imageAlt}
            src={imageUrl}
            className="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10"
          />
          <div aria-hidden="true" className="relative">
            <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoColPlusScreenshot;
