import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  Cog6ToothIcon,
  FingerPrintIcon,
  LockClosedIcon,
  ServerIcon,
} from "@heroicons/react/20/solid";

const coreValues = [
  {
    name: "Innovation Through Interdisciplinary Collaboration.",
    description:
      "We thrive on the synergy of diverse expertise, combining insights from engineering, chemistry, data science, and more with the practical knowledge of clinicians and field scientists. This interdisciplinary approach fosters innovation and addresses complex health challenges in novel ways.",
    icon: FingerPrintIcon,
  },
  {
    name: "Commitment to Accessibility and Usability.",
    description:
      "We prioritize the development of user-friendly mHealth applications that are accessible regardless of one’s geographic or economic conditions. Our focus on usability ensures that our technologies are adopted and effectively utilized in the settings that need them most.",
    icon: ArrowPathIcon,
  },
  {
    name: "Data-Driven Decision Making.",
    description:
      "We are committed to transforming anecdotal evidence into actionable data. By creating data-rich environments and developing sophisticated analysis tools, we empower decision-makers with the information needed to make informed, timely health interventions.",
    icon: ServerIcon,
  },
  {
    name: "Integration and Standardization.",
    description:
      "We advocate for the seamless integration of mobile health technologies with existing health records and surveillance systems. Our efforts extend to contributing to global standards in mHealth to ensure reliability, security, and effectiveness across different settings.",
    icon: CloudArrowUpIcon,
  },
  {
    name: "Empowerment through Technology.",
    description:
      "We believe in democratizing health technology by developing open-source, low-code/no-code tools that enable local innovators to address their unique challenges. This empowerment accelerates the adoption of health innovations and maximizes their impact.",
    icon: LockClosedIcon,
  },
  {
    name: "Ethical Responsibility and Security",
    description:
      "We maintain the highest standards of ethics and security, particularly in the development of diagnostic tools and data handling. Our technologies incorporate advanced security measures to prevent tampering and ensure the authenticity of diagnostic results.",
    icon: Cog6ToothIcon,
  },
];

export default function Example() {
  return (
    <div className="bg-gray-900 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-7xl lg:mx-0">
          <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Our Mission
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            The MHGH Lab is dedicated to advancing global health through
            innovative software solutions that leverage mobile technology and
            connected devices. We aim to transform healthcare delivery in
            under-resourced areas by developing and deploying robust, accessible
            mHealth applications that integrate seamlessly with diagnostics and
            epidemiology surveillance infrastructures. Our mission is driven by
            the belief that technology, when thoughtfully applied, can
            significantly improve health outcomes worldwide.
          </p>
        </div>
        <h2 className="pt-4 text-base font-semibold leading-7 text-mhghLightBlue-400">
          Our lab has the following core values:
        </h2>
        <dl className="mx-auto mt-4 grid max-w-2xl grid-cols-1 gap-8 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-16">
          {coreValues.map((coreValue) => (
            <div key={coreValue.name} className="relative pl-9">
              <dt className="inline font-semibold text-white">
                <coreValue.icon
                  aria-hidden="true"
                  className="absolute left-1 top-1 h-5 w-5 text-mhghLightBlue-400"
                />
                {coreValue.name}
              </dt>{" "}
              <dd className="inline">{coreValue.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
