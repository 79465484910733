import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  FunnelIcon,
  MinusIcon,
  PlusIcon,
  Bars3Icon,
  Squares2X2Icon,
} from "@heroicons/react/20/solid";

import { useState } from "react";
import Card from "./Card"; // Assume Card is already implemented
import publicationList from "./PublicationList"; // Import your list

import { countryCodeToEmoji } from "../shared/utilities";
import { LinkIcon } from "@heroicons/react/24/solid";
import { faUserGraduate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PublicationShell() {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [isCardView, setIsCardView] = useState(true); // Default is card view
  const [activeFilters, setActiveFilters] = useState({
    countries: [],
    tags: [],
    undergrad: null, // For undergrad filtering
    collection: "publications", // Default to "publications"
    disease: [], // For disease filtering
  });

  // Dynamically generate unique options from publicationList
  const uniqueCountries = [
    ...new Set(publicationList.flatMap((p) => p.countries)),
  ];
  const uniqueTags = [...new Set(publicationList.flatMap((p) => p.tags))];
  const uniqueDiseases = [
    ...new Set(publicationList.flatMap((p) => p.disease || [])), // Flatten and handle missing disease field
  ];

  const [sortOptions, setSortOptions] = useState([
    { name: "Newest", key: "date", order: "desc", current: true },
    { name: "Oldest", key: "date", order: "asc", current: false },
    { name: "Alphabetical (A-Z)", key: "title", order: "asc", current: false },
    { name: "Alphabetical (Z-A)", key: "title", order: "desc", current: false },
  ]);

  // Add filters, including Disease
  const filters = [
    {
      id: "countries",
      name: "Countries",
      options: uniqueCountries.map((country) => ({
        value: country,
        label: country.toUpperCase(),
        checked: activeFilters.countries.includes(country),
      })),
    },
    {
      id: "tags",
      name: "Tags",
      options: uniqueTags.map((tag) => ({
        value: tag,
        label: tag,
        checked: activeFilters.tags.includes(tag),
      })),
    },
    {
      id: "disease",
      name: "Disease",
      options: uniqueDiseases.map((disease) => ({
        value: disease,
        label: disease.charAt(0).toUpperCase() + disease.slice(1), // Capitalize first letter
        checked: activeFilters.disease.includes(disease),
      })),
    },
    {
      id: "undergrad",
      name: "Undergraduate Involvement",
      options: [
        {
          value: true,
          label: "Yes",
          checked: activeFilters.undergrad === true, // Handle checked for "Yes"
        },
        {
          value: false,
          label: "No",
          checked: activeFilters.undergrad === false, // Handle checked for "No"
        },
      ],
    },
    {
      id: "collection",
      name: "Collection",
      options: [
        {
          value: "publications",
          label: "Peer-reviewed Publications",
          checked: activeFilters.collection === "publications",
        },
        {
          value: "press",
          label: "Press",
          checked: activeFilters.collection === "press",
        },
      ],
    },
  ];

  // Handle filter changes, including disease
  const handleFilterChange = (filterType, value) => {
    setActiveFilters((prev) => {
      if (filterType === "undergrad" || filterType === "collection") {
        return {
          ...prev,
          [filterType]: prev[filterType] === value ? null : value, // Toggle between options
        };
      }

      const currentValues = prev[filterType] || [];
      const newValues = currentValues.includes(value)
        ? currentValues.filter((item) => item !== value) // Remove if already selected
        : [...currentValues, value]; // Add new selection

      return { ...prev, [filterType]: newValues };
    });
  };

  // Filter publications based on activeFilters, including disease
  const filteredPublications = publicationList.filter((publication) => {
    const matchesCountry =
      !activeFilters.countries.length ||
      activeFilters.countries.some((country) =>
        publication.countries.includes(country)
      );

    const matchesTag =
      !activeFilters.tags.length ||
      publication.tags.some((tag) => activeFilters.tags.includes(tag));

    const matchesUndergrad =
      activeFilters.undergrad === null || // If null, show all
      activeFilters.undergrad === publication.undergrad; // Otherwise match true/false

    const matchesCollection =
      activeFilters.collection === null || // If null, show all
      activeFilters.collection === publication.collection; // Match the collection

    const matchesDisease =
      !activeFilters.disease.length || // If no diseases are selected, show all
      activeFilters.disease.some((disease) =>
        publication.disease.includes(disease)
      );

    return (
      matchesCountry &&
      matchesTag &&
      matchesUndergrad &&
      matchesCollection &&
      matchesDisease
    );
  });

  // Sort publications based on the active sort option
  const activeSortOption = sortOptions.find((option) => option.current);
  const sortedPublications = [...filteredPublications].sort((a, b) => {
    const key = activeSortOption.key;

    if (key === "date") {
      const dateA = new Date(a[key]);
      const dateB = new Date(b[key]);
      return activeSortOption.order === "asc" ? dateA - dateB : dateB - dateA;
    }

    if (activeSortOption.order === "asc") {
      return a[key].localeCompare(b[key]);
    } else {
      return b[key].localeCompare(a[key]);
    }
  });

  const handleSortChange = (name) => {
    // Update current sort option
    setSortOptions((prevSortOptions) =>
      prevSortOptions.map((option) =>
        option.name === name
          ? { ...option, current: true }
          : { ...option, current: false }
      )
    );
  };

  return (
    <div className="bg-white">
      <div>
        {/* Mobile filter dialog */}
        <Dialog
          open={mobileFiltersOpen}
          onClose={setMobileFiltersOpen}
          className="relative z-40 lg:hidden"
        >
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-black bg-opacity-25 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
          />

          <div className="fixed inset-0 z-40 flex">
            <DialogPanel
              transition
              className="relative ml-auto flex h-full w-full max-w-xs transform flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl transition duration-300 ease-in-out data-[closed]:translate-x-full"
            >
              <div className="flex items-center justify-between px-4">
                <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                <button
                  type="button"
                  onClick={() => setMobileFiltersOpen(false)}
                  className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                </button>
              </div>

              {/* Filters */}
              <form className="mt-4 border-t border-gray-200 mx-4">
                {filters.map((section) => (
                  <Disclosure
                    key={section.id}
                    as="div"
                    className="border-b border-gray-200 py-6"
                  >
                    <h3 className="-my-3 flow-root">
                      <DisclosureButton className="group flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                        <span className="font-medium text-gray-900">
                          {section.name}
                        </span>
                        <span className="ml-6 flex items-center">
                          <PlusIcon
                            aria-hidden="true"
                            className="h-5 w-5 group-data-[open]:hidden"
                          />
                          <MinusIcon
                            aria-hidden="true"
                            className="h-5 w-5 [.group:not([data-open])_&]:hidden"
                          />
                        </span>
                      </DisclosureButton>
                    </h3>
                    <DisclosurePanel className="pt-6">
                      <div className="space-y-4">
                        {section.options.map((option) => (
                          <div key={option.value} className="flex items-center">
                            <input
                              type="checkbox"
                              id={`filter-${section.id}-${option.value}`}
                              checked={
                                section.id === "undergrad"
                                  ? activeFilters.undergrad === option.value
                                  : activeFilters[section.id]?.includes(
                                      option.value
                                    )
                              } // Handle boolean and array checked states separately
                              onChange={() =>
                                handleFilterChange(section.id, option.value)
                              }
                              className="h-4 w-4 rounded border-gray-300 text-mhghDarkBlue-600 focus:ring-mhghDarkBlue-500"
                            />
                            <label
                              htmlFor={`filter-${section.id}-${option.value}`}
                              className="ml-3 text-sm text-gray-600"
                            >
                              {option.label}
                            </label>
                          </div>
                        ))}
                      </div>
                    </DisclosurePanel>
                  </Disclosure>
                ))}
              </form>
            </DialogPanel>
          </div>
        </Dialog>

        <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="flex items-baseline justify-between border-b border-gray-200 pb-6 pt-24">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900">
              Pubs and Press
            </h1>

            <div className="flex items-center">
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <MenuButton className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                    Sort
                    <ChevronDownIcon
                      aria-hidden="true"
                      className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                    />
                  </MenuButton>
                </div>

                <MenuItems
                  transition
                  className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                  <div className="py-1">
                    {sortOptions.map((option) => (
                      <MenuItem key={option.name}>
                        <button
                          className={classNames(
                            option.current
                              ? "font-medium text-gray-900"
                              : "text-gray-500",
                            "block px-4 py-2 text-sm"
                          )}
                          onClick={() => handleSortChange(option.name)} // Trigger sort change
                        >
                          {option.name}
                        </button>
                      </MenuItem>
                    ))}
                  </div>
                </MenuItems>
              </Menu>

              <button
                type="button"
                className="-m-2 ml-5 p-2 text-gray-400 hover:text-gray-500 sm:ml-7"
                onClick={() => setIsCardView(!isCardView)} // Toggle the view
              >
                <span className="sr-only">
                  {isCardView ? "Switch to List View" : "Switch to Card View"}
                </span>
                {isCardView ? (
                  <Bars3Icon aria-hidden="true" className="h-5 w-5" />
                ) : (
                  <Squares2X2Icon aria-hidden="true" className="h-5 w-5" />
                )}
              </button>
              <button
                type="button"
                onClick={() => setMobileFiltersOpen(true)}
                className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
              >
                <span className="sr-only">Filters</span>
                <FunnelIcon aria-hidden="true" className="h-5 w-5" />
              </button>
            </div>
          </div>

          <section aria-labelledby="products-heading" className="pb-24 pt-6">
            <h2 id="products-heading" className="sr-only">
              Products
            </h2>

            <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
              {/* Filters */}
              <form className="hidden lg:block">
                {filters.map((section) => (
                  <Disclosure
                    key={section.id}
                    as="div"
                    className="border-b border-gray-200 py-6"
                  >
                    <h3 className="-my-3 flow-root">
                      <DisclosureButton className="group flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                        <span className="font-medium text-gray-900">
                          {section.name}
                        </span>
                        <span className="ml-6 flex items-center">
                          <PlusIcon
                            aria-hidden="true"
                            className="h-5 w-5 group-data-[open]:hidden"
                          />
                          <MinusIcon
                            aria-hidden="true"
                            className="h-5 w-5 [.group:not([data-open])_&]:hidden"
                          />
                        </span>
                      </DisclosureButton>
                    </h3>
                    <DisclosurePanel className="pt-6">
                      <div className="space-y-4">
                        {section.options.map((option) => (
                          <div key={option.value} className="flex items-center">
                            <input
                              type="checkbox"
                              id={`filter-${section.id}-${option.value}`}
                              checked={
                                section.id === "undergrad"
                                  ? activeFilters.undergrad === option.value
                                  : activeFilters[section.id]?.includes(
                                      option.value
                                    )
                              } // Handle boolean and array checked states separately
                              onChange={() =>
                                handleFilterChange(section.id, option.value)
                              }
                              className="h-4 w-4 rounded border-gray-300 text-mhghDarkBlue-600 focus:ring-mhghDarkBlue-500"
                            />
                            <label
                              htmlFor={`filter-${section.id}-${option.value}`}
                              className="ml-3 text-sm text-gray-600"
                            >
                              {option.label}
                            </label>
                          </div>
                        ))}
                      </div>
                    </DisclosurePanel>
                  </Disclosure>
                ))}
              </form>

              {/* Product grid */}
              <div className="lg:col-span-3">
                <div
                  className={
                    isCardView
                      ? "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6"
                      : "grid grid-cols-1 gap-2"
                  }
                >
                  {sortedPublications.map((publication) =>
                    isCardView ? (
                      <Card key={publication.id} publication={publication} />
                    ) : (
                      <div
                        key={publication.id}
                        className="border px-2 py-1 leading-tight"
                      >
                        <h2 className="text-lg font-bold">
                          {publication.title}
                          {/* Link to publication */}
                          {publication.link && (
                            <span className="ml-2 inline-block">
                              <a
                                href={publication.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-mhghDarkBlue-600 hover:text-mhghDarkBlue-800"
                                title="View Publication"
                              >
                                <LinkIcon className="h-5 w-5" />
                              </a>
                            </span>
                          )}
                        </h2>
                        <p className="text-gray-500">{publication.venue}</p>
                        <p className="text-gray-400">
                          {new Date(publication.date).toLocaleDateString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }
                          )}
                        </p>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
}
