import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { FaLinkedin } from "react-icons/fa"; // Import LinkedIn icon

export default function TeamModal({ person, onClose }) {
  // console.log("person:", person);
  return (
    <Dialog open={!!person} onClose={onClose} className="relative z-10">
      <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto flex items-center justify-center">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg md:max-w-xl md:w-xl sm:p-6 max-h-[80vh] overflow-y-auto">
            <div className="absolute top-4 right-4">
              <button onClick={onClose}>
                <XCircleIcon className="h-6 w-6 text-gray-600 hover:text-gray-800" />
              </button>
            </div>
            <div>
              <div className="mx-auto flex h-40 w-40 items-center justify-center rounded-full bg-gray-100 overflow-hidden">
                <img
                  alt={`${person.firstName} ${person.lastName}`}
                  src={person.imageComponent}
                  className="object-cover"
                />
              </div>
              <div className="mt-3 sm:mt-5">
                <DialogTitle
                  as="h3"
                  className="text-xl font-bold text-gray-900 text-left"
                >
                  {person.firstName} {person.lastName}
                  <span
                    className={`ml-2 inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${
                      person.status === "active"
                        ? "bg-green-50 text-green-800 ring-green-600/20"
                        : "bg-gray-50 text-gray-800 ring-gray-600/20"
                    }`}
                  >
                    {person.status === "active"
                      ? "Active MHGH Member"
                      : "MHGH Alum"}
                  </span>
                </DialogTitle>
                <p className="mt-2 text-lg text-gray-700 text-left">
                  {person.title}
                </p>

                {/* LinkedIn icon */}
                {person.linkedinUrl && (
                  <div className="mt-3">
                    <a
                      href={person.linkedinUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center text-blue-700 hover:text-blue-900"
                    >
                      <FaLinkedin className="h-5 w-5 mr-2" />
                    </a>
                  </div>
                )}

                {/* Hide email if status is alum */}
                {person.role === "pi" && (
                  <p className="mt-2 text-sm text-gray-500 text-left">
                    Email: {person.email}
                  </p>
                )}

                {/* Display person details */}
                <div className="mt-4 text-gray-700 space-y-4 text-left">
                  {person.details.affiliationTitle && (
                    <div>
                      <strong>MHGH Role: </strong>{" "}
                      {person.details.affiliationTitle}
                    </div>
                  )}
                  {person.details.researchInterests && (
                    <div>
                      <strong>Research Interests: </strong>{" "}
                      {person.details.researchInterests}
                    </div>
                  )}
                  {person.details.education &&
                    person.details.education.length > 0 && (
                      <div>
                        <strong>Education:</strong>
                        <ul className="">
                          {person.details.education.map((edu, idx) => (
                            <li key={idx}>{edu}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                  {person.details.bio && person.details.bio.length > 0 && (
                    <div>
                      <strong>Bio:</strong>
                      {person.details.bio.map((paragraph, idx) => (
                        <p key={idx} className="mb-2">
                          {paragraph}
                        </p>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}